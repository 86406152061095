import React from 'react';
import { Col } from 'react-grid-system';
import { css } from 'emotion';
import { lighten } from 'polished';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';

const CtaItem = ({ bgColor, label, title, to, xs, sm, md, lg, xl }) => {
  const cssWrapper = css`
    a {
      display: block;
      transition: all 0.3s ease-in-out;
      background-color: ${bgColor};

      &:hover {
        background-color: ${lighten('0.1', bgColor)};
      }
    }
  `;

  const cssItem = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
    height: 250px;
  `;

  const cssLabel = css`
    font-size: 2.2rem;
  `;

  const cssTitle = css`
    font-size: 4rem;
    font-family: ${themeVars.root.headerFontFamily};

    & > {
      span {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  `;

  return (
    <Col className={cssWrapper} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Link to={`/${to}/`}>
        <div className={cssItem}>
          <p className={cssLabel}>{label}</p>
          <p className={cssTitle}>
            <span>{title}</span>
          </p>
        </div>
      </Link>
    </Col>
  );
};

CtaItem.defaultProps = {
  bgColor: themeVars.root.primary,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 6,
  xl: null,
};

CtaItem.propTypes = {
  /** The background colour for the item */
  bgColor: PropTypes.string,

  /** The label of the item */
  label: PropTypes.string.isRequired,

  /** The title for the action */
  title: PropTypes.string.isRequired,

  /** The URL to navigate to when clicked */
  to: PropTypes.string.isRequired,

  /** The grid column size */
  xs: PropTypes.number,

  /** The grid column size */
  sm: PropTypes.number,

  /** The grid column size */
  md: PropTypes.number,

  /** The grid column size */
  lg: PropTypes.number,

  /** The grid column size */
  xl: PropTypes.number,
};

export default CtaItem;
