import React from 'react';
import { Container, Col, Row } from 'react-grid-system';
import { css } from 'emotion';
import { rgba } from 'polished';
import { graphql, StaticQuery } from 'gatsby';
import shortid from 'shortid';

import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

import NavMenu from '../Common/NavMenu';
import NavMenuList from '../Common/NavMenuList';
import NavMenuListItem from '../Common/NavMenuListItem';

import image from '../../assets/images/rec.jpg';
import SocialList from '../Common/SocialList';

// Component
// -----
const Footer = () => {
  const footer = css`
    position: relative;
    background-color: ${themeVars.root.darkAlt};
  `;

  const cssFooterMenu = css`
    li {
      margin-left: 0 !important;
      margin-right: 4rem;
    }
  `;

  const footerEnd = css`
    ${breakup.medium`text-align: right;`}

    p {
      color: ${rgba('white', 0.6)};
    }
  `;

  const cssLink = css`
    color: ${themeVars.root.light};
  `;

  const footerImage = css`
    display: block;

    img {
      max-height: 6rem;
    }
  `;

  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressWpApiMenusMenusItems(name: { eq: "Footer" }) {
            items {
              title
              object_slug
              classes
              url
              target
            }
          }
        }
      `}
      render={(data) => {
        return (
          <section className={footer}>
            <Container fluid>
              <Row className="pad-top pad-bottom">
                <Col xs={10} push={{ xs: 1 }} md={6} className={cssFooterMenu}>
                  <NavMenu>
                    <NavMenuList>
                      {data.wordpressWpApiMenusMenusItems.items.map((item) => {
                        // Show a custom link if the menu item has button class
                        if (item.classes === 'button') {
                          return (
                            <li key={shortid.generate()}>
                              <a aria-label={item.title} href={item.url}>
                                {item.title}
                              </a>
                            </li>
                          );
                        }

                        return (
                          <React.Fragment key={shortid.generate()}>
                            <NavMenuListItem label={item.title} url={`${item.object_slug}`} />
                          </React.Fragment>
                        );
                      })}
                    </NavMenuList>
                  </NavMenu>

                  <SocialList color="#FFF" />
                </Col>
                <Col xs={10} push={{ xs: 1 }} md={3}>
                  <div className={footerEnd}>
                    <p>
                      <a
                        aria-label="Vivid Creative"
                        className={cssLink}
                        href="https://www.vividcreative.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Site by Vivid
                      </a>
                    </p>
                    <p>&copy; Copyright 2019</p>
                  </div>
                </Col>
                <Col xs={10} push={{ xs: 1 }} md={1}>
                  <div className={footerEnd}>
                    <a
                      aria-label="REC"
                      className={footerImage}
                      href="https://www.rec.uk.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img src={image} alt="The Recruitment &amp; Employment Confederation" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        );
      }}
    />
  );
};

export default Footer;
