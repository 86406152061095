import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const cssIcon = css`
  margin-right: 15px;
`;

const SocialIcon = ({ url, icon, color }) => {
  return (
    <li className={cssIcon}>
      <a aria-label="Social media profile" href={url}>
        <FontAwesomeIcon icon={icon} color={color} size="lg" />
      </a>
    </li>
  );
};

SocialIcon.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  color: PropTypes.string.isRequired,
};

export default SocialIcon;
