import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';

const Content = ({ content, color }) => {
  const copy = css`
    color: ${color};

    p {
      color: ${color};
    }
  `;

  return <div className={copy} dangerouslySetInnerHTML={{ __html: content }} />;
};

Content.defaultProps = {
  color: themeVars.root.dark,
};

Content.propTypes = {
  content: PropTypes.string.isRequired,

  /** Color of the Content */
  color: PropTypes.string,
};

export default Content;
