import React from 'react';
import { Container, Row } from 'react-grid-system';
import PropTypes from 'prop-types';

const CtaList = ({ children }) => {
  return (
    <section>
      <Container fluid style={{ padding: '0' }}>
        <Row nogutter>{children}</Row>
      </Container>
    </section>
  );
};

CtaList.propTypes = {
  /** Array of CtaItem components */
  children: PropTypes.node.isRequired,
};

export default CtaList;
